import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import MainFeature1 from "components/features/TwoColWithButton.js";
import TeamCardGrid from "ProfileThreeColGrid.js";
import mainimage from "images/BlueBGLogo.png";

import Nav from "nav";
const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Nav />
      <MainFeature1
        heading="We are washing up energy"
        description="Here at HydroNest, we have cut out the middle man. You generate Our power directly with expensive tap based hydro. You sell kits to install the hydro taps to your friends in a triangle structure, And we get all the electricity, that you are forced to buy back from us for a massive markup.
        
        We belive we are the best choice for your energy needs, and we will hope you will join is in our mission to power everything with pyramid schemes!"
        imageSrc={mainimage}
      />

      {/* <TeamCardGrid
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
    </AnimationRevealPage>
  );
};
